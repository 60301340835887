<template>
  <div>
    <Form />
  </div>
</template>

<script>
  import Form from '../components/Form.vue';
  
  export default {
    name: 'AdminUsersUpdate',
    components: {
      Form,
    }
  }
</script>