<template>
  <div>
    <b-card>
      <validation-observer ref="CreateUserForm" #default="{invalid}">
        <b-form autocomplete="off">
          <b-row>
            <b-col md="4">
              <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  vid="user-name"
                  rules="required"
              >
                <b-form-group autocomplete="off" label="Nome" label-for="user-input-name">
                  <b-form-input id="user-input-name" placeholder="Nome" v-model="user.name"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="5">
              <validation-provider
                  #default="{ errors }"
                  name="email"
                  vid="user-email"
                  rules="required|email"
              >
                <b-form-group autocomplete="off" label="Email" label-for="mc-email">
                  <b-form-input id="mc-email" placeholder="Email" v-model="user.email"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <b-form-group label="Telefone" label-for="mc-phone">
                <b-form-input id="mc-phone" placeholder="99 99999-9999" v-model="user.phone" v-mask="'## ####-####'"/>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                  #default="{ errors }"
                  name="userType"
                  vid="user-type"
                  rules="required"
              >
                <b-form-group label="Tipo de usuário" label-for="extract-filter-status">
                  <v-select v-if="canEditType()"
                    id="extract-filter-type"
                    :options="typeOptions"
                    v-model="user.user_type"
                    :reduce="(value) => value.value"
                  />
                  <b-form-input v-else id="extract-filter-type" :value="user.user_type" disabled/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group label="Status" label-for="extract-filter-status">
                <v-select
                  id="extract-filter-status"
                  :options="statusOptions"
                  v-model="user.status"
                  :reduce="(value) => value.value"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group autocomplete="off" label="Senha" label-for="user-input-password">
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input
                      id="user-input-password"
                      v-model="user.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="user-input-password"
                      placeholder="Password"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility('passwordFieldType')" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group autocomplete="off" label="Confirmação de senha" label-for="user-input-password-confirmation">
                <validation-provider
                  #default="{ errors }"
                  name="Confirmação de senha"
                  vid="password-confirmation"
                  rules="required|confirmed:password"
                  >
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input
                      id="user-input-password-confirmation"
                      v-model="user.password_confirmation"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="user-input-password-confirmation"
                      placeholder="Password"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility('passwordFieldType')" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button type="submit" variant="primary" class="mr-1" :disabled="invalid" @click="save">
              Salvar
            </b-button>
            <b-button type="reset" variant="outline-primary" @click="back">
              Voltar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import EventBus from "@/services/event_bus";
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email, cnpj } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BForm,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
export default {
  name: 'AdminUsersForm',
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user: {
        id: null,
        name: '',
        email: '',
        phone: '',
        status: 'active',
        user_type: 'admin',
        password: '',
        password_confirmation: '',
      },
      statusOptions: [
        { value: 'active', label: 'Ativo' },
        { value: 'inactive', label: 'Inativo' },
        { value: 'blocked', label: 'Bloqueado' }
      ],
      typeOptions: [
        { value: 'admin', label: 'Administrador' },
        { value: 'parceiro', label: 'Parceiro' }
      ],
      required,
      email,
      cnpj
    }
  },
  mounted() {
    this.fetchUser();
  },
  methods: {
    save() {
      console.log(this.user)
      if (this.user.id) return this.update();
      this.create();
    },
    create () {
      try {
        this.$store.dispatch("admin/createUser", this.user).then((response) => {
          this.$swal.fire({
            title: 'Sucesso!',
            text: 'Usuário cadastrado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
          this.$router.push({ name: "admin-users.index" });
        });
      } catch (e) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Não foi possível cadastrar o usuário!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
    update () {
      try {
        this.$store.dispatch("admin/updateUser", this.user).then((response) => {
          this.$swal.fire({
            title: 'Sucesso!',
            text: 'Usuário atualizado com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok',
          });
          this.$router.push({ name: "admin-users.index" });
        });
      } catch (e) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Não foi possível atualizar o usuário!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },
    async fetchUser () {
      const id = this.$route.params.id;
      if (!id) return;
      const { data } = await this.$store.dispatch("admin/fetchUser", id);
      this.user = data;
    },
    canEditType () {
      return !this.user.id || this.typeOptions.map(option => option.value).includes(this.user.user_type);
    },
    back () {
      this.$router.push({ name: "admin-users.index" });
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {},
}
</script>